import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Image, TextInput, Pressable } from 'react-native';
import React, { useContext, useEffect, useState } from 'react';
import { getFirestore, doc, collection, limit, where, getDoc, deleteDoc, getDocs, query, setDoc, addDoc, updateDoc, serverTimestamp, increment } from "firebase/firestore";
import { useWindowDimensions } from 'react-native';
import firebase from 'firebase/compat/app';
import emailjs from '@emailjs/browser';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import RadioGroup from 'react-native-radio-buttons-group';

// Initialize Firebase
const firebaseConfig = {
  apiKey: Constants.manifest.extra.apiKey,
  authDomain: Constants.manifest.extra.authDomain,
  projectId: Constants.manifest.extra.projectId,
  storageBucket: Constants.manifest.extra.storageBucket,
  messagingSenderId: Constants.manifest.extra.messagingSenderId,
  appId: Constants.manifest.extra.appId
};
let Firebase;

if (firebase.apps.length === 0) {
  Firebase = firebase.initializeApp(firebaseConfig);
  //console.log("firebase :D");
}

let db = getFirestore();


export default function App() {
  const { height, width } = useWindowDimensions();
  let innerContainerStyle;


  useFonts({
    'Choplin-SemiBold': require('./assets/Choplin-SemiBold.otf'),
    'Choplin-Light': require('./assets/Choplin-Light.otf'),
  });
  if(width > 768){
    innerContainerStyle = {
      margin: '15px',
      width: '40%',
      maxWidth: '40%',
      borderColor: '#292929',
      borderWidth: '1px',
      borderRadius: '10px',
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 5.46,
      elevation: 6,
    }
  }
  else {
    innerContainerStyle = {
      width: '100%',
      maxWidth: '100%'
    }    
  }

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [page, setPage] = useState('index');
  const [emailError, setEmailError] = useState('');
  const [codeError, setCodeError] = useState(null);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [questionError, setQuestionError] = useState('');

  const [questionData, setQuestionData] = useState({});
  const [radioButtons, setRadioButtons] = useState([]);

  const [justifyButton, setJustifyButton] = useState('center');

  const [resultsSent, setResultsSent] = useState(false);
  const [results, setResults] = useState({c: 0, m: 0, p: 0, s: 0,});

  const [allQuestionsData, setAllQuestionsData] = useState([]);

  const [shuffledQuestions, setShuffledQuestions] = useState({});

  function shuffle(array, questionNum) {
    let currentIndex = array.length,  randomIndex;
    
    //check if questionNum is a property of shuffledQuestions
    if(shuffledQuestions[questionNum]){
      return shuffledQuestions[questionNum];
    }
    else{
      // While there remain elements to shuffle.
      while (currentIndex != 0) {
    
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
    
        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }
      //add the shuffled array to shuffledQuestions
      setShuffledQuestions({...shuffledQuestions, [questionNum]: array});

      return array;
    }
  }

  function setQuestions(){
    setDoc(doc(db, 'questions', '1'), {
      questionNumber: 1,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Ready to jump into new tasks, energized by challenges",
      m: "Sensitive to the emotions of others",
      p: "Agreeable, easy to get along with",
      s: "Excitable, passionate, quick to say yes",
    });    
    setDoc(doc(db, 'questions', '2'), {
      questionNumber: 2,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Motivated to achieve goals, eager to be the leader",
      m: "Logical, gathers facts and data, likes lists",
      p: "Flexible, able to adjust to new conditions, calm in the midst of chaos",
      s: "Energetic, lively, exhibits love and affection openly",
    });    
    setDoc(doc(db, 'questions', '3'), {
      questionNumber: 3,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Self-motivated, usually right",
      m: "Careful, thoughtful, doesn't rush into things",
      p: "Avoids conflict and drama, levelheaded",
      s: "Fun to be around, a good storyteller with a sense of humor",
    });
    setDoc(doc(db, 'questions', '4'), {
      questionNumber: 4,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Confident and assertive with my opinions",
      m: "Detail-oriented, orderly, tidy",
      p: "Cooperative, open to the needs and ideas of others",
      s: "Upbeat, always sees the best in people and situations",
    });
    setDoc(doc(db, 'questions', '5'), {
      questionNumber: 5,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "A determined problem solver, finds a way to get it done",
      m: "Serious, avoids being impulsive or flashy",
      p: "Considerate, kind, polite",
      s: "Playful, spontaneous, lives in the moment",
    });
    setDoc(doc(db, 'questions', '6'), {
      questionNumber: 6,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Efficient and effective with my time and effort",
      m: "Precise, thorough, has very high standards",
      p: "Relaxed, hard to provoke or annoy",
      s: "Friendly, outgoing, talkative",
    });
    setDoc(doc(db, 'questions', '7'), {
      questionNumber: 7,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Can be quick-tempered",
      m: "Not confident in social situations",
      p: "Struggles to make decisions quickly",
      s: "Can dominate conversations",
    });
    setDoc(doc(db, 'questions', '8'), {
      questionNumber: 8,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Unafraid to disagree with others, bossy",
      m: "Sometimes feels isolated, too introspective",
      p: "Rarely sets goals for myself, unclear on purpose or direction",
      s: "Uncomfortable with solitude, afraid of missing out",
    });
    setDoc(doc(db, 'questions', '9'), {
      questionNumber: 9,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Can be overbearing, prefers to be in control",
      m: "Prone to feeling sad or gloomy, gets hung up on bad news",
      p: "Unsure of myself, reluctant to jump into conversations or activities",
      s: "Seeks diversions from tasks, can waste time or daydream",
    });
    setDoc(doc(db, 'questions', '10'), {
      questionNumber: 10,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Persuades others to do things my way, thinks the end justifies the means",
      m: "Uses sarcasm or the silent treatment when upset",
      p: "Struggles to express enthusiasm",
      s: "Often exaggerates stories, compulsive talker",
    });
    setDoc(doc(db, 'questions', '11'), {
      questionNumber: 11,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Reluctant to admit mistakes or say I’m sorry, rarely compliments others",
      m: "Skeptical, sees obstacles, thinks about the worst-case scenario",
      p: "No sense of urgency, resents being pushed",
      s: "Fickle, forgetful, makes excuses, repeats stories",
    });
    setDoc(doc(db, 'questions', '12'), {
      questionNumber: 12,
      header: "Choose the ONE description you most naturally identify with.",
      subheader: "I am someone who (is)...",
      c: "Dislikes tears and emotions in others, can be unsympathetic",
      m: "Hard to please, can be critical",
      p: "Slow to get moving, a procrastinator",
      s: "Struggles with follow-through, especially when things stop being fun",
    });
    setDoc(doc(db, 'questions', '13'), {
      questionNumber: 13,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      c: "I am decisive and my decisions are usually right.",
      m: "I want all the information before I make a decision.",
      p: "I sometimes just want someone else to make the decision for me.",
      s: "I like to talk through my decisions out loud with others.",
    }); 
    setDoc(doc(db, 'questions', '14'), {
      questionNumber: 14,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      p: "I like knowing a little about a lot of things and a lot about the things that really interest me.",
      m: "I like to stick to the schedule.",
      c: "I like accomplishing things and feeling productive.",
      s: "I like coming up with creative ideas people will enjoy.",
    });
    
    setDoc(doc(db, 'questions', '15'), {
      questionNumber: 15,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      m: "I tend to notice imperfections and mistakes.",
      p: "I tend to forget or disregard things I'm not interested in.",
      s: "I tend to not even think of bad or sad things.",
      c: "I tend to not show my emotions.",
    });
    
    setDoc(doc(db, 'questions', '16'), {
      questionNumber: 16,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      s: "I frequently tell stories about my personal experiences.",
      m: "I consider every thought and detail before communicating.",
      c: "I cut to the chase in conversations; I don't need every last detail.",
      p: "I hesitate to speak up and advocate for myself when I disagree with the group.",
    });
    
    setDoc(doc(db, 'questions', '17'), {
      questionNumber: 17,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      p: "I want the freedom to do projects in my own time frame.",
      m: "I want time to process my thoughts and answers so I know they're correct.",
      c: "I want to know the why behind a project so I know it's worth my time.",
      s: "I want variety and spontaneity with projects.",
    });
    
    setDoc(doc(db, 'questions', '18'), {
      questionNumber: 18,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      c: "I'm good at delegating to others.",
      m: "I'm good at teaching others.",
      s: "I'm good at inspiring others.",
      p: "I'm good at caring for others.",
    });
    
    setDoc(doc(db, 'questions', '19'), {
      questionNumber: 19,
      header: "Choose the ONE statement that best represents you.",
      subheader: "*none*",
      s: "I get stressed when I feel like I'm not liked.",
      m: "I get stressed when I feel like I'm not understood.",
      c: "I get stressed when I feel like I'm not in control.",
      p: "I get stressed when I feel like I'm being pulled into conflict.",
    });
    setDoc(doc(db, 'questions', '20'), {
      questionNumber: 20,
      header: "Choose the ONE phrase you are most likely to say in your head or out loud.",
      subheader: "*none*",
      c: "You go. I have better things to do.",
      m: "What will we be doing?",
      p: "Do I have to go?",
      s: "Who else is going to be there?",
      });
      
      setDoc(doc(db, 'questions', '21'), {
      questionNumber: 21,
      header: "Choose the ONE phrase you are most likely to say in your head or out loud.",
      subheader: "*none*",
      s: "That sounds like fun!",
      c: "Hurry up! We're late.",
      m: "Are you sure that's safe?",
      p: "I'm good with whatever.",
      });
      
      setDoc(doc(db, 'questions', '22'), {
      questionNumber: 22,
      header: "Choose the ONE phrase you are most likely to say in your head or out loud.",
      subheader: "*none*",
      p: "No worries, it will all work out.",
      s: "I'll do it if someone will do it with me.",
      c: "It's done. I took care of it.",
      m: "I've been giving it some thought.",
      });
      
      setDoc(doc(db, 'questions', '23'), {
      questionNumber: 23,
      header: "Choose the ONE phrase you are most likely to say in your head or out loud.",
      subheader: "*none*",
      s: "I'd be happy to help!",
      c: "I didn't mean it that way.",
      p: "No, thanks. I'll just watch.",
      m: "I'm not sure I trust them.",
      });
      
      setDoc(doc(db, 'questions', '24'), {
      questionNumber: 24,
      header: "Choose the ONE phrase you are most likely to say in your head or out loud.",
      subheader: "*none*",
      c: "Just give me the bottom line.",
      m: "That won't work. I've already planned to...",
      s: "I totally forgot about that. I'm sorry.",
      p: "I don't really care. You decide.",
      });

    setDoc(doc(db, 'questions', '25'), {
      questionNumber: 25,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      c: "Ambitious",
      m: "Analytical",
      p: "Easygoing",
      s: "Energetic",
    });
    setDoc(doc(db, 'questions', '26'), {
      questionNumber: 26,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      c: "Fearless",
      m: "Empathetic",
      s: "Enthusiastic",
      p: "Agreeable",
    });
    
    setDoc(doc(db, 'questions', '27'), {
      questionNumber: 27,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      c: "Capable",
      s: "Charming",
      m: "Cautious",
      p: "Calm",
    });
    
    setDoc(doc(db, 'questions', '28'), {
      questionNumber: 28,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      m: "Organized",
      p: "Content",
      s: "Positive",
      c: "Assertive",
    });
    
    setDoc(doc(db, 'questions', '29'), {
      questionNumber: 29,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      p: "Considerate",
      m: "Reserved",
      c: "Resourceful",
      s: "Lighthearted",
    });
    
    setDoc(doc(db, 'questions', '30'), {
      questionNumber: 30,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      p: "Patient",
      m: "Detail-oriented",
      c: "Productive",
      s: "People-oriented",
    });
    
    setDoc(doc(db, 'questions', '31'), {
      questionNumber: 31,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      c: "Bossy",
      s: "Distractible",
      m: "Pessimistic",
      p: "Indifferent",
    });
    
    setDoc(doc(db, 'questions', '32'), {
      questionNumber: 32,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      p: "Detached",
      m: "Moody",
      c: "Demanding",
      s: "Naïve",
    });
    
    setDoc(doc(db, 'questions', '33'), {
      questionNumber: 33,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      p: "Sluggish",
      m: "Suspicious",
      c: "Self-centered",
      s: "Scatterbrained",
    });
    setDoc(doc(db, 'questions', '34'), {
      questionNumber: 34,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      p: "Unmotivated",
      m: "Unforgiving",
      s: "Undisciplined",
      c: "Unsympathetic",
    });
    setDoc(doc(db, 'questions', '35'), {
      questionNumber: 35,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      s: "Dramatic",
      c: "Impatient",
      p: "Indecisive",
      m: "Insecure",
    });
    
    setDoc(doc(db, 'questions', '36'), {
      questionNumber: 36,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      m: "Judgmental",
      s: "Restless",
      c: "Blunt",
      p: "Apathetic",
    });
    
    setDoc(doc(db, 'questions', '37'), {
      questionNumber: 37,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      s: "Imaginative",
      p: "Even-keeled",
      c: "Driven",
      m: "Compassionate",
    });
    
    setDoc(doc(db, 'questions', '38'), {
      questionNumber: 38,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      c: "Enjoys working hard",
      s: "Turns crisis into comedy",
      p: "Well-balanced",
      m: "Musical or artistic",
    });
    
    setDoc(doc(db, 'questions', '39'), {
      questionNumber: 39,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      s: "Loud laugh",
      c: "Brave",
      m: "Methodical",
      p: "Quiet but witty",
    });
    
    setDoc(doc(db, 'questions', '40'), {
      questionNumber: 40,
      header: "Choose the ONE word/phrase of the four options that best describes you.",
      subheader: "Which of the below options describes you best?",
      s: "Curious",
      m: "Deep and thoughtful",
      p: "Patient leader",
      c: "Delegates well",
    });
  }

  useEffect(() => {
    //setQuestions();
    const getAllQuestionsData = async () => {
      const allQuestionsServData = await getDocs(collection(db, 'questions'));
      const allQuestionsDataArr = allQuestionsServData.docs.map((doc) => doc.data());
      //sort allQuestionsDataArr by questionNumber
      allQuestionsDataArr.sort((a, b) => a.questionNumber - b.questionNumber);
      setAllQuestionsData(allQuestionsDataArr);
    }
    getAllQuestionsData();
  }, []);

  useEffect(() => {
    const getQuestionData = async () => {
      const questionData = allQuestionsData[questionNumber-1];
      const userData = await getDoc(doc(db, "codes", code));
      const userDataObj = userData.data();

      let mSelected = false;
      let pSelected = false;
      let sSelected = false;
      let cSelected = false;

      //if the user has already answered this question, set the radio button to the correct value
      if('question'+questionNumber in userDataObj){
        if(userDataObj['question'+questionNumber].response == 'm'){
          mSelected = true;
        }
        else if(userDataObj['question'+questionNumber].response == 'p'){
          pSelected = true;
        }
        else if(userDataObj['question'+questionNumber].response == 's'){
          sSelected = true;
        }
        else if(userDataObj['question'+questionNumber].response == 'c'){
          cSelected = true;
        }
      }

      let labelStyle = {textAlign: 'left', fontFamily: 'Choplin-Light', fontSize: '18px', color: '#292929',}
      setQuestionData(questionData);
      setRadioButtons(shuffle([
        {
          id: 'm',
          label: questionData.m,
          value: 'm',
          labelStyle: labelStyle,
          onPress: async () => {
            await updateDoc(doc(db, "codes", code), {
              ['question'+questionNumber]: {
                response: 'm',
              }
            });
          },
          selected: mSelected,
        },
        {
          id: 'p',
          label: questionData.p,
          value: 'p',
          labelStyle: labelStyle,
          onPress: async () => {
            await updateDoc(doc(db, "codes", code), {
              ['question'+questionNumber]: {
                response: 'p',
              }
            });
          },
          selected: pSelected,
        },
        {
          id: 's',
          label: questionData.s,
          value: 's',
          labelStyle: labelStyle,
          onPress: async () => {
            await updateDoc(doc(db, "codes", code), {
              ['question'+questionNumber]: {
                response: 's',
              }
            });
          },
          selected: sSelected,
        },
        {
          id: 'c',
          label: questionData.c,
          value: 'c',
          labelStyle: labelStyle,
          onPress: async () => {
            await updateDoc(doc(db, "codes", code), {
              ['question'+questionNumber]: {
                response: 'c',
              }
            });
          },
          selected: cSelected,
        },
      ], questionNumber));

    }
    const calcualteResults = async () => {
        //calculate the results
        const userData = await getDoc(doc(db, "codes", code));
        const userDataObj = userData.data();
        let mCount = 0;
        let pCount = 0;
        let sCount = 0;
        let cCount = 0;
        for(let i = 1; i <= 40; i++){
          if(userDataObj['question'+i].response == 'm'){
            mCount++;
          }
          else if(userDataObj['question'+i].response == 'p'){
            pCount++;
          }
          else if(userDataObj['question'+i].response == 's'){
            sCount++;
          }
          else if(userDataObj['question'+i].response == 'c'){
            cCount++;
          }
        }
        let results = {
          m: mCount,
          p: pCount,
          s: sCount,
          c: cCount,
        }
        //

        setResults(results);
        setPage('results');
    }
    if(questionNumber > 0){
      setQuestionError('');
      getQuestionData();
      if(questionNumber == 1){
        setJustifyButton('center');
      }
      else{
        setJustifyButton('space-between');
      }
    }
    if(questionNumber == -1){
      calcualteResults();
    }
  }, [questionNumber]);

  function onPressRadioButton(radioButtonsArray) {
    setRadioButtons(radioButtonsArray);
  }

  if(page == 'index'){
    return (
      <View style={{width: '100%', height: '100%', alignItems: 'center'}}>
        <View style={[styles.container,innerContainerStyle]}>
          <Image resizeMode='contain' style={{width:'30%', height: undefined, aspectRatio: 135 / 76}}source={require('./assets/TheColorWorksGroup_Black_Vertical.png')} />
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '32px', color: '#292929', textAlign: 'center',}}>Temperament Assessment</Text>
  
  
          <Text style={{padding: '15px', fontFamily: 'Choplin-SemiBold', fontSize: '24px', color: '#292929', textAlign: 'center',}}>Email:</Text>
          <TextInput
            style={{height: 40,
              borderColor: 'gray',
              borderWidth: 1,
              width: '40%',
              borderRadius: '10px',
              padding: '10px',
              fontFamily: 'Choplin-Light',
              fontSize: '16px',
              color: '#292929',
              textAlign: 'center',}}
            onChangeText={text => setEmail(text)}
            value={email}
          />
          {emailError != '' ? <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>{emailError}</Text> : null}
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#292929', textAlign: 'center',}}>This is where we will send your results.</Text>
  
          <Text style={{padding: '15px', fontFamily: 'Choplin-SemiBold', fontSize: '24px', color: '#292929', textAlign: 'center',}}>Book Code:</Text>
          <TextInput
            style={{height: 40,
              borderColor: 'gray',
              borderWidth: 1, width: '40%',
              borderRadius: '10px',
              padding: '10px',
              fontFamily: 'Choplin-Light',
              fontSize: '16px',
              color: '#292929',
              textAlign: 'center',
            }}
            onChangeText={text => {
              //don't allow any non-alpabetical characters
              if(text.match(/^[a-zA-Z]*$/)){
                setCode(text.toUpperCase());
              }
              }
            }
            value={code}
            maxLength={6}
          />

          {codeError}
          <Text style={{paddingTop: '15px', paddingBottom: '3px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#292929', textAlign: 'center',}}>Enter the code found on the first page of I Said This, You Heard That.</Text><Text style={{fontFamily: 'Choplin-SemiBold', color: '#E45A53', paddingBottom: '15px'}}>{'Please Note: Each code can be used only once!'}</Text>
        
          <Pressable
            style={args => {
              if (!args.pressed) { return [
                {backgroundColor: '#60D2A4',
                  margin: '10px',
                  padding: '15px',
                  borderRadius: '10px',
                  width: '30%',
                  borderColor: '#292929',
                  borderWidth: '1px',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.23,
                  shadowRadius: 5.46,
                  elevation: 6,}]}
                else { return [
                  {backgroundColor: '#F0F2F4',
                  margin: '10px',
                  padding: '15px',
                  borderRadius: '10px',
                  width: '30%',
                  borderColor: '#292929',
                  borderWidth: '1px',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 0.2,
                  },
                  shadowOpacity: 0.23,
                  shadowRadius: 5.46,
                  elevation: 6,}]}
                }
              }
            onPress={async () => {
              let errorExists = false;
              if(email == ''){
                setEmailError('Please enter your email');
                errorExists = true;
              }
              //Checks if the email is valid
              else if(!email.includes('@') || !email.includes('.')){
                setEmailError('Please enter a valid email');
                errorExists = true;
              }
              else{
                setEmailError('');
              }
              //Checks if the code is present in the Firestore database and that it has not been used
              //If it is valid, it will set the page to questions and the questionNumber to 1
              //If it is not valid, it will set the error to 'Please enter a valid code'
              //If it has been used, it will take the user to the last completed question or the results page
              if(code == ''){
                setCodeError(<Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>{'Please enter a code'}</Text>);
                errorExists = true;
              }
              else if(code.length != 6){
                setCodeError(<Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>
                  {'Oops! The code you have entered is invalid. Try again in case a typo is to blame. If you continue having trouble, reach out to us at '}
                  <a href="mailto:support@thecolorworksgroup.com">
                    support@thecolorworksgroup.com
                  </a>
                  {'.'}
                </Text>
                );
                errorExists = true;
              }
              else{
                setCodeError(null);
              }
               
              if(errorExists == false){
                let codeRef = doc(db, "codes", code);
                let codeDoc = await getDoc(codeRef);
                let codeData = codeDoc.data();
                if(codeData == undefined){
                  setCodeError(<Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>
                    {'Oops! The code you have entered is invalid. Try again in case a typo is to blame. If you continue having trouble, reach out to us at '}
                    <a href="mailto:support@thecolorworksgroup.com">
                      support@thecolorworksgroup.com
                    </a>
                    {'.'}
                  </Text>
                  );
                }
                else if(codeData.used == true){
                  if(codeData.email != email.toLowerCase()){
                    setCodeError(<Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>
                      {'This code has already been redeemed, but fear not! Additional assessments can be purchased at '}
                      <a href="https://thecolorworksgroup.com">
                        thecolorworksgroup.com
                      </a>
                      {'.'}
                    </Text>
                    );
                  }
                  else{
                    if(codeData.questionIndex > 0){
                      //console.log(codeData.questionIndex);
                      setQuestionNumber(codeData.questionIndex);
                      setPage('questions');
                    }
                    else if(codeData.questionIndex == -1){
                      setQuestionNumber(-1);
                    }
                  }
                }
                else {
                  //update the code to be used and set the email
                  //set email to lowercase

                  await updateDoc(codeRef, {
                    used: true,
                    email: email.toLowerCase(),
                    //testDate should be the current date as a string in formay MM/DD/YYYY
                    testDate: new Date().toLocaleDateString(),
                    questionIndex: 1,
                  });
                  setQuestionNumber(1);
                  setPage('questions');
                }
              }
            }
          }
          >
            <Text style={{fontFamily: 'Choplin-SemiBold', fontSize: '24px', color: '#292929', textAlign: 'center',}}>Start</Text>
          </Pressable>

        
        </View>
      </View>
    );
  }
  if(page == 'questions'){
    return (
      <View style={{width: '100%', height: '100%', alignItems: 'center'}}>
        <View style={[styles.container,innerContainerStyle]}>
          <Image resizeMode='contain' style={{width:'30%', height: undefined, aspectRatio: 135 / 76}}source={require('./assets/TheColorWorksGroup_Black_Vertical.png')} />
          <Text style={{padding: '15px', marginTop: '24px', fontFamily: 'Choplin-Light', fontSize: '22px', color: '#292929', textAlign: 'center', borderBottomWidth: 1, borderBottomColor: 'black'}}>{'Question '+questionNumber+' of 40'}</Text>
  
          <Text style={{width: '92%',  padding: '15px', fontFamily: 'Choplin-Light', fontSize: '26px', color: '#292929', textAlign: 'center'}}>{questionData['header']}</Text>

          <View style={{
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '80%',
          }}>
            {questionData['subheader'] == '*none*' ? null : <Text style={{padding: '15px', fontFamily: 'Choplin-SemiBold', fontSize: '24px', color: '#292929', textAlign: 'center'}}>{questionData['subheader']}</Text>}
            
            <RadioGroup 
              radioButtons={radioButtons} 
              onPress={onPressRadioButton} 
              containerStyle= {{alignItems: 'flex-start',justifyContent: 'flex-start',}}
            />

          </View>

          {questionError != '' && <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '12px', color: '#E45A53', textAlign: 'center',}}>{questionError}</Text>}

          <View style={{width:'67%'}}>
          <View style={{flexDirection: 'row', justifyContent: justifyButton, width: '100%', padding: '15px'}}>
            {questionNumber > 1 && <Pressable
              style={args => {
                if (!args.pressed) { return [
                  {backgroundColor: '#60D2A4',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    width: '45%',
                    borderColor: '#292929',
                    borderWidth: '1px',
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.23,
                    shadowRadius: 5.46,
                    elevation: 6,}]}
                  else { return [
                    {backgroundColor: '#F0F2F4',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    width: '45%',
                    borderColor: '#292929',
                    borderWidth: '1px',
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 0.2,
                    },
                    shadowOpacity: 0.23,
                    shadowRadius: 5.46,
                    elevation: 6,}]}
                  }
                }
              onPress={async () => {
                setQuestionNumber(questionNumber - 1);
              }
            }
            >
              <Text style={{fontFamily: 'Choplin-SemiBold', fontSize: '18px', color: '#292929', textAlign: 'center',}}>Back</Text>
            </Pressable>}
            {questionNumber < 41 && <Pressable
              style={args => {
                if (!args.pressed) { return [
                  {backgroundColor: '#60D2A4',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    width: '45%',
                    borderColor: '#292929',
                    borderWidth: '1px',
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 2,
                    },
                    shadowOpacity: 0.23,
                    shadowRadius: 5.46,
                    elevation: 6,}]}
                  else { return [
                    {backgroundColor: '#F0F2F4',
                    margin: '10px',
                    padding: '10px',
                    borderRadius: '10px',
                    width: '45%',
                    borderColor: '#292929',
                    borderWidth: '1px',
                    shadowColor: "#000",
                    shadowOffset: {
                      width: 0,
                      height: 0.2,
                    },
                    shadowOpacity: 0.23,
                    shadowRadius: 5.46,
                    elevation: 6,}]}
                  }
                }
              onPress={async () => {
                if(questionNumber < 40){
                  //get the state data
                  let currentState = await getDoc(doc(db, "codes", code));
                  let currentStateData = currentState.data();

                  //check that the current question has been answered
                  if('question'+questionNumber in currentStateData){
                    let newQuestionData = {
                      questionIndex: questionNumber + 1,
                    };
                    let codeRef = doc(db, "codes", code);
                    await updateDoc(codeRef, newQuestionData);
                    setQuestionNumber(questionNumber + 1);
                  }
                  else{
                    setQuestionError('Please select an answer before continuing.');
                  }
                }
                else if(questionNumber == 40){
                  let currentState = await getDoc(doc(db, "codes", code));
                  let currentStateData = currentState.data();
                  if('question'+questionNumber in currentStateData){
                    let newQuestionData = {
                      questionIndex: -1,
                    };
                    let codeRef = doc(db, "codes", code);
                    await updateDoc(codeRef, newQuestionData);
                    setQuestionNumber(-1);
                  }
                  else{
                    setQuestionError('Please select an answer before continuing.');
                  }
                }
              }
            }
            >
              <Text style={{fontFamily: 'Choplin-SemiBold', fontSize: '18px', color: '#292929', textAlign: 'center',}}>{questionNumber < 40 ? 'Next' : 'Submit'}</Text>
            </Pressable>}
          </View>
          </View>


        
        </View>
      </View>
    );
  }
  if(page == 'results'){
    return (
      <View style={{width: '100%', height: '100%', alignItems: 'center'}}>
        <View style={[styles.container,innerContainerStyle]}>
          <Image resizeMode='contain' style={{width:'30%', height: undefined, aspectRatio: 135 / 76}}source={require('./assets/TheColorWorksGroup_Black_Vertical.png')} />
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '32px', color: '#292929', textAlign: 'center',}}>Your Results:</Text>
  
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '24px', color: '#FFCF77', textAlign: 'center',}}>{'Yellow: ' + results['s']}</Text>
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '24px', color: '#E45A53', textAlign: 'center',}}>{'Red: ' + results['c']}</Text>
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '24px', color: '#3D88AC', textAlign: 'center',}}>{'Blue: ' + results['m']}</Text>
          <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '24px', color: '#60D2A4', textAlign: 'center',}}>{'Green: ' + results['p']}</Text>

        
          {resultsSent ? 
          <View>
            <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '18px', color: '#292929', textAlign: 'center',}}>Your results have been sent to your email.</Text>
            <Text style={{padding: '15px', fontFamily: 'Choplin-Light', fontSize: '18px', color: '#292929', textAlign: 'center',}}>To purchase additional copies of the Temperament Assessment, <Text style={{textDecorationLine: 'underline', color: '#3D88AC', fontFamily: 'Choplin-SemiBold'}} onPress={() => window.open('https://thecolorworksgroup.com/assessment/')}>click here.</Text></Text>
          </View>
          : <Pressable
            style={args => {
              if (!args.pressed) { return [
                {backgroundColor: '#60D2A4',
                  margin: '10px',
                  padding: '15px',
                  borderRadius: '10px',
                  width: '30%',
                  borderColor: '#292929',
                  borderWidth: '1px',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.23,
                  shadowRadius: 5.46,
                  elevation: 6,}]}
                else { return [
                  {backgroundColor: '#F0F2F4',
                  margin: '10px',
                  padding: '15px',
                  borderRadius: '10px',
                  width: '30%',
                  borderColor: '#292929',
                  borderWidth: '1px',
                  shadowColor: "#000",
                  shadowOffset: {
                    width: 0,
                    height: 0.2,
                  },
                  shadowOpacity: 0.23,
                  shadowRadius: 5.46,
                  elevation: 6,}]}
                }
              }
            onPress={async () => {
              //use emailjs to send an email containing the results to the user
              setResultsSent(true);
              emailjs.init(Constants.manifest.extra.emailjsPublicKey);
              emailjs.send("service_zwwnxv7","template_5koh03e",{
                book_code: code,
                to_email: email,
                mCount: results['m'],
                sCount: results['s'],
                cCount: results['c'],
                pCount: results['p'],
                }).then(function(response) {
                 console.log('SUCCESS!', response.status, response.text);
              }, function(error) {
                 console.log('FAILED...', error);            
              });
            }
          }
          >
            <Text style={{fontFamily: 'Choplin-SemiBold', fontSize: '18px', color: '#292929', textAlign: 'center',}}>Send Results</Text>
          </Pressable>}

        
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxWidth: '1080px',
    paddingTop: '20px',
  },
  titleTest: {
    fontFamily: 'Choplin-SemiBold',
    fontSize: '48px',
    color: '#000000',
    textAlign: 'center',
  }
  
});
